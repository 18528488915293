<template>
  <b-overlay
    :show="spinner"
    spinner-variant="primary"
  >
    <b-card
      no-body
      class="business-card"
    >
      <b-card-body>
        <cambiarPasswordForm
          :validarPassword="false"
          :configuracion="true"
          usuarioSubmit="Editar Cuenta"
          :usuario="userData"
          @processCuenta="editarPassword"
        />
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardBody, BOverlay,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const cambiarPasswordForm = () => import('@/layouts/components/Navbar/Configuraciones/CambiarPassword/CambiarPasswordForm.vue')

export default {
  components: {
    BCard,
    BCardBody,
    BOverlay,
    cambiarPasswordForm,
  },
  data() {
    return {
      spinner: false,
      userData: {},
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      getUsuarios: 'usuarios/getUsuarios',
    }),
  },
  watch: {
    getUsuarios() {
      this.userData = this.getUsuarios.find(u => u.id === this.user.id)
    },
  },
  mounted() {
    this.fetchUsuarios()
  },
  methods: {
    ...mapActions({
      fetchUsuarios: 'usuarios/fetchUsuarios',
      updatePasswordUsuario: 'usuarios/updatePasswordUsuario',
    }),
    ...mapMutations('usuarios', ['setUsuario']),
    editarPassword(usuario) {
      this.spinner = true
      this.updatePasswordUsuario(usuario).then(message => {
        if (message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Contraseña editada con éxito 👍',
              text: 'Su contraseña fue editada con éxito!',
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
          {
            timeout: 4000,
          })
          this.$router.replace({
            name: 'home',
          })
        } else {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.spinner = false
      })
    },
  },
}
</script>

<style>

</style>
